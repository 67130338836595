<!-- 考试管理首页 -->
<template>
  <div>
    <div class="main">
      <div style="font-size: 26px; font-weight: bold">门店管理</div>
      <div>
        <div>
          <el-button
            type="primary"
            style="height: 34px; line-height: 10px; margin-right: 67px"
            class="cinput"
            @click='add'
            >+添加门店</el-button
          >
          <!-- <el-radio-group v-model="radio" style="height: 34px" class="cinput">
            <el-radio-button label="全部"></el-radio-button>
            <el-radio-button label="导师"></el-radio-button>
            <el-radio-button label="学员"></el-radio-button>
            <el-radio-button label="院长"></el-radio-button>
            <el-radio-button label="审核员"></el-radio-button>
          </el-radio-group> -->
        </div>
        <div>
          <el-cascader
            @change="selectcity"
            placeholder="地区选择"
            :options="citys"
            class="cinput ac"
            clearable
            v-model='code'
          ></el-cascader>
          <el-select
            v-model="isValidity"
            placeholder="门店状态"
            class="cinput ac"
            style="margin: 0 23px"
          >
            <el-option
              v-for="item in isValidityList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <div style="width: 221px">
            <el-input
              v-model="input"
              placeholder="输入门店名称"
              class="cinput ac"
              @keyup.enter.native="search"
              style="margin-left:20px"
            ></el-input>
            <div
              style="
                display: flex;
                height: 34px;
                width: 34px;
                align-items: center;
                background: #f7f7f7;
              "
            >
              <img
                v-if="isshow"
                src="../../images/search2.png"
                alt=""
                @click="search"
                style="background: #f7f7f7"
              />
              <img
                v-if="isshow == false"
                src="../../images/search1.png"
                alt=""
                style="background: #f7f7f7"
              />
               <!-- <img
                src="../../images/search2.png"
                alt=""
                style="background: #f7f7f7"
                 @click="search"
              />  -->
            </div>
          </div>
        </div>
      </div>

      <el-table
        :header-cell-style="{ background: '#F7F7F7' }"
        :data="tableData"
        style="width: 100%; min-height: 600px"
      >
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="name" label="门店名称" align="center" width="180">
        </el-table-column>
        <el-table-column prop="location" align="center" label="详细地址">
        </el-table-column>
        <el-table-column prop="directorUserName" align="center" label="院长">
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <div>
              <el-link type="primary" :underline="false" style="margin: 0 25px" @click='details(scope.row)'
                >查看详情</el-link
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex; align-items: center; justify-content: center">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-sizes="[10, 15, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="562px"
      :before-close="handleClose"
    >
      <div
        style="
          display: flex;
          align-items: center;
          font-size: 20px;
          font-weight: bold;
          margin-bottom:38px
        "
      >
        <div class="line"></div>
        <div>{{edit==true?'添加门店':'查看详情'}}</div>
      </div>
     <el-form label-position="right" label-width="100px" >
  <el-form-item label="店名：">
    <el-input v-model="name" :disabled="!edit"></el-input>
  </el-form-item>
  <el-form-item label="门店状态：">
     <el-select
            v-model="isValidity2"
           
           :disabled="!edit"
          >
            <el-option
              v-for="item in isValidityList2"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
  </el-form-item>
  <el-form-item label="院长：">
     <div>{{userName}}</div>
  </el-form-item>
  <el-form-item label="联系方式：">
    <el-input v-model="phone"  :disabled="!edit"></el-input>
  </el-form-item>
   <el-form-item label="地址：">
    <el-cascader
            @change="selectcity2"
            placeholder="地区选择"
            :options="citys2"
            :disabled="!edit"
            filterable
            v-model='code2'
          ></el-cascader>
  </el-form-item>
   <el-form-item label="详细地址：">
    <el-input v-model="location" type='textarea' :rows="5"  :disabled="!edit"></el-input>
  </el-form-item>
</el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save" v-if='edit'>确 定</el-button>
        <el-button type="primary" @click="edit=true" v-if='!edit'>编辑</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {cityList} from '../../utils/cityCode'
import {saveOrUpdate,storepageList} from '../../apis/index'
export default {
  data() {
    return {
      isValidityList:[
         {
          id:'',
          name:'全部'
        },
        {
          id:0,
          name:'下线'
        },
        {
          id:1,
          name:'可用'
        }
      ],
       isValidityList2:[
        {
          id:0,
          name:'下线'
        },
        {
          id:1,
          name:'可用'
        }
      ],
      isValidity:'',
       isValidity2:'',
      id:'',
      userName:'',
      userId:'',
      edit:true,//是否可以编辑
      pageIndex:1,
      pageSize:10,
      total:0,
      searchStr:'',
      name:'',
      phone:'',
      location:'',
      radio1:'1',
      dialogVisible: false,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
      ],
      value: "",
      isshow: false,
      isshow2: true,
      currentPage: 1,
      radio: "全部",
      input: "",
      tableData: [],
    };
  },
  watch: {
    input(newdata, olddata) {
      console.log(newdata);
      if (newdata) {
        this.isshow = true;
      } else {
        this.input=''
        this.list()
        this.isshow = false;
      }
    },
    radio(newdata, olddata) {
      if (newdata == "已发布") {
        this.isshow2 = true;
      } else {
        this.isshow2 = false;
      }
    },
    isValidity(){
      this.list()
    },
  },
  created(){
      let codes=cityList()//获取省市区数据
      this.citys=codes
      // this.code=['330000', '330100', '330102']
      this.code=''
      this.citys2=codes
      this.code2=''
      // this.code2=['330000', '330100', '330102']
      this.list()
  },
  methods: {
   async list(){//分页获取
      const params={
        province:Number(this.code2[0]),
        city:Number(this.code2[1]),
        area:Number(this.code[2]),
        pageIndex:this.pageIndex,
        pageSize:this.pageSize,
        searchStr:this.input,
        isValidity:this.isValidity
      }
     const {data}=await storepageList(params)
     if(data.code==200){
       this.tableData=data.data.records
       this.total=Number(data.data.total)
     }
     console.log(data)
    },
   async save(){//新增门店管理保存
   
      const params={
        isValidity:this.isValidity2,
        name:this.name,
        phone:this.phone,
        location:this.location,
        province:this.code2[0],
        city:this.code2[1],
        area:this.code2[2],
        userName:this.userName,
        userId:this.userId,
        id:this.id
      }
     const {data}=await saveOrUpdate(params)
     if(data.code==200){
       this.$message.success('操作成功')
        this.dialogVisible=false
        this.code2=[]
        this.list()
     }else{
       this.$message.error(data.msg)
     }
     console.log(data)
    },
    details(row){//查看详情
    console.log(row)
        this.dialogVisible = true;
        this.edit=false
        this.name=row.name,
        this.phone=row.name,
        this.location=row.location
        let c1=row.province.toString()
        let c2=row.city.toString()
        let c3=row.area.toString()
        this.code2=[c1,c2,c3]
        this.userName=row.directorUserName
        this.userId=row.userId
        this.id=row.id
        this.isValidity2=row.isValidity
        
    },
    add(){//添加门店
        this.edit=true
        this.dialogVisible = true;
        this.name='',
        this.phone='',
        this.location=''
        this.code2=[]
        this.userName=''
        this.userId=''
        this.id=''
    },
    selectcity(value){
      console.log(value)
      this.list()
    },
     selectcity2(value){
      console.log(value)
    },
    handleClose() {
      this.dialogVisible = false;
    },
    search() {
     this.list()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize=val
      this.list()
    },
    handleCurrentChange(val) {
      this.pageIndex=val
      this.list()
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.el-dialog__footer{
 text-align:center;
}
.inp /deep/.el-input__inner{
  border: none;
  font-size: 20px;
}
.inp2 /deep/.el-input__inner,/deep/.el-radio__label{
  // border: none;
  font-size: 20px;
}
.line {
  width: 9px;
  height: 22px;
  border-radius: 6px;
  background: #409eff;
  margin-right: 11px;
}
/deep/.el-dialog__body {
  padding: 30px 36px;
}
/deep/.el-radio-group span {
  width: 106px;
}
.main {
  // min-height: 800px;
  background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
}
.main > div:first-child {
  font-size: 26px;
  // font-weight: bold;
}
.main > div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  height: 34px;
  margin: 18px 0;
  div {
    display: flex;
    align-items:center;
    img {
      width: 25px;
      height: 25px;
    }
  }
}
/deep/.el-radio-button__inner {
  height: 34px;
  line-height: 10px;
}
.cinput /deep/.el-input__inner {
  height: 34px;
  line-height: 10px;
}
/deep/.el-table::before {
  height: 0;
}
.ac /deep/.el-input__inner {
  border: none;
  background: #f7f7f7;
  // margin-right: 11px;
}
.red {
  color: #ff1b0b;
}
</style>
